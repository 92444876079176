import { Box, VStack, Flex } from "@chakra-ui/react";
import { Display03, Paragraph02 } from "~/components/Texts";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Img from "react-optimized-image";
import NotificationsImage from "~/public/marketing/landing_analytics_notifications.png";
import ChartsImage from "~/public/marketing/landing_analytics_charts.png";
import VisitorsImage from "~/public/marketing/landing_analytics_visitors.png";

export default function LandingSectionFeatureAnalytics() {
  return (
    <AnimatedEntryOnViewport>
      <Flex
        direction={{ base: "column", lg: "row" }}
        w="100%"
        color="light.brand.strong"
      >
        <VStack flex="1" align="flex-start" spacing="8">
          <Box
            px="2"
            borderRadius="brand"
            borderWidth="2px"
            borderColor="action.secondary"
            color="action.secondary"
          >
            <Display03>Entendé tu negocio</Display03>
          </Box>
          <Paragraph02 maxW="600px">
            <b>Información precisa en tiempo real.</b> Acceso a información
            actualizada de tu negocio en todo momento para cada miembro de tu
            equipo.
          </Paragraph02>
          <AnimatedEntryOnViewport animation="popIn">
            <Img
              src={NotificationsImage}
              webp
              sizes={[100, 200, 400]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Notificaciones de nuevo visitante en la tienda, nueva venta y nuevo mensaje de chat"
            />
          </AnimatedEntryOnViewport>
        </VStack>
        <Box boxSize="8" />
        <VStack flex="1" spacing="10">
          <AnimatedEntryOnViewport animation="popIn">
            <Img
              src={ChartsImage}
              webp
              sizes={[150, 300, 600]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Gráfico de facturación, ventas en línea y visitas a la tienda."
            />
          </AnimatedEntryOnViewport>
          <AnimatedEntryOnViewport animation="popIn">
            <Img
              src={VisitorsImage}
              webp
              sizes={[150, 300, 600]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Gráfico de origen del tráfico a la tienda"
            />
          </AnimatedEntryOnViewport>
        </VStack>
      </Flex>
    </AnimatedEntryOnViewport>
  );
}
