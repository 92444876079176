import { useInView } from "react-intersection-observer";
import { Box, VStack, Button, Flex, Stack, useTheme } from "@chakra-ui/react";
import { Display02, Display03, Paragraph02 } from "~/components/Texts";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import FeaturePoint from "~/components/marketing/FeaturePoint";
import { HiCode } from "react-icons/hi";
import { FaRegHandPointer } from "react-icons/fa";
import { IoMdColorPalette } from "react-icons/io";
import Img from "react-optimized-image";
import CharacterImage from "~/public/marketing/landing_design_shop_character.png";
import Link from "next/link";

export default function LandingSectionSmallCallToAction() {
  const chakraTheme = useTheme();

  return (
    <Box
      position="relative"
      w="100%"
      h={{ base: "900px", sm: "1050px", md: "1150px", xl: "1300px" }}
      py={{ sm: "6", xl: "10" }}
      alignSelf="center"
      backgroundColor={{
        sm: "light.background.lite",
        xl: "light.background.superLite",
      }}
      color="light.brand.strong"
    >
      <Box position="relative" w="100%" h="100%" spacing="8">
        <Box
          display={{ base: "none", md: "block" }}
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          px="10"
        >
          <Background />
        </Box>

        <VStack
          w="100%"
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          px="4"
          justify="center"
          spacing="4"
        >
          <AnimatedEntryOnViewport height="auto">
            <VStack pt="10">
              <Display02 textAlign="center">
                Diseñá{" "}
                <mark
                  style={{
                    background:
                      "linear-gradient(180deg,rgba(255,255,255,0) 50%, #EFF2F6 50%)",
                    color: chakraTheme.colors.action.primary,
                  }}
                >
                  tu tienda
                </mark>{" "}
                en minutos
              </Display02>

              <Display03 textAlign="center">Sólo o acompañado 😉</Display03>
            </VStack>
          </AnimatedEntryOnViewport>

          <VStack w="100%" spacing="6">
            <DemoVideo />

            <Stack
              direction={{ base: "column", sm: "row" }}
              w="100%"
              justify={{ base: "flex-start", sm: "center" }}
              spacing={{ base: "2", sm: "1", md: "4", lg: "10" }}
            >
              <FeaturePoint
                width={"auto"}
                height="auto"
                icon={<HiCode />}
                title="Sin programar"
              />
              <FeaturePoint
                width="auto"
                height="auto"
                icon={<FaRegHandPointer />}
                title="Fácil de usar"
              />
              <FeaturePoint
                width="auto"
                height="auto"
                icon={<IoMdColorPalette />}
                title="Personalizable"
              />
            </Stack>
          </VStack>

          <Flex
            direction={{ base: "row-reverse", md: "row" }}
            pt={{ base: "0", lg: "10" }}
            align="center"
          >
            <Img
              src={CharacterImage}
              webp
              sizes={[80, 125]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Persona diseñando su tienda"
            />
            <Box boxSize="4" />
            <VStack
              flex="1"
              maxW="600px"
              align="flex-start"
              spacing={{ base: "4", lg: "10" }}
            >
              <Paragraph02>
                <b>Tu tienda online se ajusta automáticamente</b> para vender en
                dispositivos móviles, redes sociales y por buscadores como
                Google.
              </Paragraph02>
              {/*
              <Link href="/carterina">
                <Button size="md" colorScheme="secondary">
                  Ver tienda de ejemplo
                </Button>
              </Link>
              */}
            </VStack>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
}

const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1304 1099"
    width="100%"
    height="100%"
    fill="none"
  >
    <path
      fill="#fbfbfb"
      d="M1296.09 85.922L9.085.602C4.268.283.268 4.269.571 9.087L68.607 1091.12a7.994 7.994 0 008.386 7.48l1164.807-58.57a8.02 8.02 0 007.59-7.53l54.15-938.134a7.99 7.99 0 00-7.45-8.443z"
    />
  </svg>
);

const DemoVideo = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <Box
      ref={ref}
      p="2"
      borderRadius="brand"
      backgroundColor="light.background.superLite"
    >
      {inView && (
        <video autostart="true" autoPlay playsInline muted width="1200">
          <source
            src="/marketing/shop_design_animation.webm"
            type="video/webm"
          />
          <source src="/marketing/shop_design_animation.mp4" type="video/mp4" />
        </video>
      )}
    </Box>
  );
};
