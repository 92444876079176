import {
  Box,
  VStack,
  HStack,
  DarkMode,
  Button,
  Center,
  Text,
} from "@chakra-ui/react";
import { Paragraph04 } from "~/components/Texts";
import { Desktop } from "~/components/MediaQuery";
import { FiArrowRight } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";
import Link from "next/link";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import AnimatedTextEntryOnViewport from "~/components/animations/AnimatedTextEntryOnViewport";
import MotionBox from "~/components/MotionBox";
import Img from "react-optimized-image";
import BackgroundImage from "~/public/marketing/landing_hero_background.jpg";
import ForegroundImage from "~/public/marketing/landing_hero_right_panel.png";

export default function LandingSectionHero() {
  return (
    <Box
      position="relative"
      w="100vw"
      minH="100vh"
      backgroundColor="light.brand.strong"
    >
      <Box top="0" right="0" w="100vw" minH="100vh">
        <Img
          style={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
          }}
          src={BackgroundImage}
          webp
          sizes={[300, 600, 900, 1200, 1600]}
          densities={[1, 2]} // Retina support
          draggable="false"
          loading="lazy"
          alt="Muchos productos publicados en distintas tiendas en internet."
        />
      </Box>

      {/* Right panel*/}
      <Box
        position="absolute"
        top="0"
        right="0"
        w="100%"
        h="100%"
        pointerEvents="none"
        maxW="50vw"
      >
        <Desktop>
          <Img
            style={{
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
              objectPosition: "left",
            }}
            src={ForegroundImage}
            webp
            sizes={[300, 600, 800]}
            densities={[1, 2]} // Retina support
            draggable="false"
            loading="lazy"
            alt="Panel de control de tu tienda en internet."
          />
        </Desktop>
      </Box>

      {/* Waves */}
      <Box
        position="absolute"
        w="100%"
        display={{ base: "none", xl: "block" }}
        maxH={{ md: "5vh", lg: "10vh", xl: " 15vh" }}
        left="0"
        bottom="0"
      >
        <Waves />
      </Box>

      {/* Left panel*/}
      <Box position="absolute" left="0" top="0" w="100%" h="100%" p="4">
        <AnimatedEntryOnViewport animation="slideFromLeft">
          <VStack
            w="100%"
            h="100%"
            p={{ base: "4", sm: "8", md: "10", lg: "12", xl: "14" }}
            align="flex-start"
            justify="center"
            spacing={{ base: "1", sm: "2", md: "4", lg: "6" }}
          >
            {/* Title and subtitle */}
            <VStack
              w={{ base: "100%", md: "85%", xl: "60%" }}
              color="white"
              align="flex-start"
              spacing="6"
            >
              <AnimatedTextEntryOnViewport color="white" textStyle="display01">
                Wow! estás a un click de vender por internet
              </AnimatedTextEntryOnViewport>

              <Box maxW="400px">
                <Text textStyle={{ base: "paragraph02", md: "paragraph01" }}>
                  Creá tu tienda online en minutos y vendé más. Podés hacerlo
                  sólo o con tu equipo.
                </Text>
              </Box>
            </VStack>

            {/* Call to action */}
            <VStack w="100%" align="flex-start" spacing="4">
              <Link href="/auth/signup">
                <Button size="lg" boxShadow="lg" rightIcon={<FiArrowRight />}>
                  Creá tu tienda ahora
                </Button>
              </Link>
              <Link href="/pricing">
                <a>
                  <Text wordBreak="break-word" color="white">
                    <b>Comenzá gratis, sólo pagas después de vender</b>
                  </Text>
                </a>
              </Link>
            </VStack>

            {/*
              TODO: ENABLE THIS BACK WHEN WE'VE GOT A TUTORIAL VIDEO

            <HStack spacing="4" pt={{ base: "4", sm: "6", lg: "10" }}>
              <DarkMode>
                <Button
                  leftIcon={<FaPlay />}
                  borderRadius="brand"
                  px="4"
                  variant="outline"
                  color="white"
                >
                  Ver video tutorial
                </Button>
              </DarkMode>
            </HStack>
            */}
          </VStack>
        </AnimatedEntryOnViewport>
      </Box>

      <CursorsMovingAround />
    </Box>
  );
}

const Waves = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1202 126" fill="none">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M50.083 52.806L0 74.656V126h1202V74.656h-50.08c-50.09 0-150.25 0-250.42 16.388-23.115 3.782-46.231 8.436-69.346 13.091-77.052 15.516-154.103 31.031-231.154 14.223-47.698-10.406-95.397-33.198-143.095-55.99C405.437 37.297 352.968 12.226 300.5 3.641 200.333-12.747 100.167 30.955 50.083 52.806z"
    />
  </svg>
);

const CursorsMovingAround = () => {
  return (
    <Box
      position="absolute"
      w="100%"
      h="100%"
      top="0"
      left="0"
      pointerEvents="none"
    >
      <Box position="relative" w="100%" h="100%">
        <MotionBox
          position="absolute"
          top="0"
          right="0"
          animate={{ top: "100%", right: "60%", opacity: [1, 1, 1, 1, 0] }}
          transition={{ duration: 4.5, delay: 0.25, ease: "circInOut" }}
        >
          <UserCursor username="Frank" color="#00BCD4" rotation="180deg" />
        </MotionBox>
        <MotionBox
          position="absolute"
          top="0"
          right="100%"
          animate={{ top: "100%", right: "40%", opacity: [1, 1, 1, 1, 0] }}
          transition={{ duration: 5, delay: 0.5, ease: "circInOut" }}
        >
          <UserCursor username="Alex" color="#34B53A" rotation="230deg" />
        </MotionBox>
        <MotionBox
          position="absolute"
          top="100%"
          right="100%"
          animate={{
            top: "0",
            right: "25%",
            opacity: [1, 1, 1, 1, 0],
          }}
          transition={{ duration: 4, delay: 0.75, ease: "circInOut" }}
        >
          <UserCursor username="Juan" color="#FF9800" />
        </MotionBox>
        <MotionBox
          position="absolute"
          top="100%"
          right="0"
          animate={{ top: "0", right: "90%", opacity: [1, 1, 1, 1, 0] }}
          transition={{ duration: 5.5, delay: 0, ease: "circInOut" }}
        >
          <UserCursor username="Mica" color="#9C27B0" rotation="90deg" />
        </MotionBox>
      </Box>
    </Box>
  );
};

const UserCursor = ({ username, color, rotation = "0deg" }) => {
  const Cursor = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="32"
      viewBox="0 0 23 32"
      fill="none"
    >
      <path
        fill={color}
        d="M22.801 21.529L1.155.196A.682.682 0 00.199.195.66.66 0 000 .668v30.666c0 .274.17.52.429.62s.553.034.743-.167l8.593-9.12h12.557a.671.671 0 00.678-.665.66.66 0 00-.199-.473z"
      />
    </svg>
  );

  return (
    <HStack w="100%" h="100%" spacing="4">
      <Box transform={`rotate(${rotation})`}>
        <Cursor />
      </Box>
      <Center px="2" py="1" borderRadius="full" backgroundColor={color}>
        <Paragraph04 color="light.background.superLite">
          <b>{username}</b>
        </Paragraph04>
      </Center>
    </HStack>
  );
};
