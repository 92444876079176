import PropTypes from "prop-types";
import { cloneElement } from "react";
import { HStack, Center, useTheme, Text } from "@chakra-ui/react";
import { Label01 } from "~/components/Texts";

FeaturePoint.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  isDark: PropTypes.bool,
};
FeaturePoint.defaultProps = {
  width: "100%",
  height: "100%",
  isDark: false,
};

export default function FeaturePoint({ icon, title, width, height, isDark }) {
  const chakraTheme = useTheme();

  return (
    <HStack w={width} h={height} spacing="6">
      <Center
        p={{ base: "2", md: "4" }}
        borderRadius="full"
        backgroundColor="light.background.action"
      >
        {cloneElement(icon, {
          color: chakraTheme.colors.light.brand.strong,
          size: "24px",
        })}
      </Center>
      <Text
        textStyle={{ base: "label02", md: "label01" }}
        color={isDark ? "light.background.superLite" : "light.brand.strong"}
      >
        {title}
      </Text>
    </HStack>
  );
}
