import { Box, VStack, Flex } from "@chakra-ui/react";
import { Display03, Paragraph02 } from "~/components/Texts";
import FeaturePoint from "~/components/marketing/FeaturePoint";
import { FiShoppingCart, FiTruck, FiMail } from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Img from "react-optimized-image";
import CharacterImage from "~/public/marketing/landing_sales_process_character.png";
import EmailImage from "~/public/marketing/landing_sales_process_mail.png";

export default function LandingSectionFeatureSalesProcess() {
  return (
    <AnimatedEntryOnViewport>
      <Flex
        direction={{ base: "column", lg: "row" }}
        w="100%"
        color="light.brand.strong"
        p={{ base: "4", md: "8" }}
        borderRadius="brand"
        backgroundColor="light.brand.strong"
        color="light.background.superLite"
      >
        <VStack flex="1" align="flex-start" spacing="8">
          <Display03>Automatizá tus ventas</Display03>
          <Paragraph02 maxW="600px">
            <b>Vendé más y con menos esfuerzo.</b> Tu tienda viene totalmente
            configurada para recibir pagos y envía correos de seguimiento para
            las compras de tus clientes.
          </Paragraph02>
          <VStack spacing="4">
            <FeaturePoint
              isDark
              icon={<FiShoppingCart />}
              title="Cobrá online por tus ventas"
            />
            <FeaturePoint
              isDark
              icon={<FiTruck />}
              title="Controlá tus envíos"
            />
            <FeaturePoint
              isDark
              icon={<FiMail />}
              title="Correos de seguimiento automáticos"
            />
          </VStack>
        </VStack>
        <Box boxSize="8" />
        <VStack position="relative" flex="1" spacing="10">
          <AnimatedEntryOnViewport>
            <Img
              src={EmailImage}
              webp
              sizes={[150, 300, 600]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Email de confirmación de compra"
            />
          </AnimatedEntryOnViewport>
          <Box
            position="absolute"
            right="-10%"
            bottom="-10%"
            width={{ base: "100px", md: "150px", lg: "200px" }}
          >
            <Img
              src={CharacterImage}
              webp
              sizes={[100, 150, 200]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Persona festejando porque realizó una compra por internet"
            />
          </Box>
        </VStack>
      </Flex>
    </AnimatedEntryOnViewport>
  );
}
