import { Box, VStack, Stack, useTheme } from "@chakra-ui/react";
import { Display02, Paragraph02 } from "~/components/Texts";
import FeaturePoint from "~/components/marketing/FeaturePoint";
import { FiMessageCircle, FiHome, FiUserCheck } from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Img from "react-optimized-image";
import ChatImage from "~/public/marketing/landing_chat_mockup.png";

export default function LandingSectionChat() {
  const chakraTheme = useTheme();

  return (
    <AnimatedEntryOnViewport animation="slideFromLeft" delay={0.5}>
      <Box
        w="100%"
        h={{ base: "950px", md: "700px", xl: "800px" }}
        w="100%"
        py={{ base: "2", xl: "10" }}
        alignSelf="center"
        backgroundColor={{
          base: "light.background.lite",
          xl: "light.background.superLite",
        }}
      >
        <Box position="relative" w="100%" h="100%" spacing="8">
          <Box position="absolute" top="0" left="0" w="100%" h="100%" px="10">
            <Background />
          </Box>

          <Stack
            direction={{ base: "column", md: "row" }}
            justify="center"
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            spacing={{ base: "0", xl: "12" }}
          >
            <Box
              flex="1"
              h="100%"
              display={{ sm: "none", md: "block" }}
              pr={{ base: "0", md: "4" }}
              pb={{ base: "4", md: "0" }}
            >
              <Img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "0% 50%",
                }}
                src={ChatImage}
                webp
                sizes={[300, 600, 800]}
                densities={[1, 2]} // Retina support
                draggable="false"
                loading="lazy"
                alt="Chat incluido en tu tienda por internet"
              />
            </Box>

            <VStack
              flex={{ base: "2", md: "1" }}
              align={{ sm: "center", xl: "flex-start" }}
              px="4"
            >
              <AnimatedEntryOnViewport animation="popIn">
                <VStack
                  color="light.brand.strong"
                  align="flex-start"
                  spacing="4"
                >
                  <Display02
                    maxW="600px"
                    textAlign={{ sm: "center", md: "left" }}
                  >
                    <mark
                      style={{
                        background:
                          "linear-gradient(180deg,rgba(255,255,255,0) 50%, #F4F4F4 50%)",
                        color: chakraTheme.colors.action.primary,
                      }}
                    >
                      Chateá
                    </mark>{" "}
                    con los visitantes de tu tienda
                  </Display02>
                  <Paragraph02 maxW="600px">
                    <b>
                      Chat incluido en tu tienda, sin necesidad de programar o
                      integrar otras plataformas.
                    </b>{" "}
                    Ofrecé la misma experiencia que las mejores marcas con
                    herramientas avanzadas para el soporte a tus clientes.
                  </Paragraph02>
                  <VStack pt="6" spacing="4">
                    <FeaturePoint
                      icon={<FiMessageCircle />}
                      title="Chat integrado en tu tienda"
                    />
                    <FeaturePoint
                      icon={<FiHome />}
                      title="Mensaje de bienvenida"
                    />
                    <FeaturePoint
                      icon={<FiUserCheck />}
                      title="Atendé a los visitantes con tu equipo"
                    />
                  </VStack>
                </VStack>
              </AnimatedEntryOnViewport>
            </VStack>
          </Stack>
        </Box>
      </Box>
    </AnimatedEntryOnViewport>
  );
}

const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1307 759"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#fbfbfb"
      d="M1298.7 59.323L9.166.419C4.319.197.393 4.306.834 9.137L68.575 751.47a8 8 0 008.244 7.268l1167.931-40.47a8 8 0 007.69-7.328l53.87-642.957c.38-4.521-3.08-8.452-7.61-8.659z"
    />
  </svg>
);
