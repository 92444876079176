import { useEffect } from "react";
import { VStack, LightMode } from "@chakra-ui/react";
import Page from "~/components/Page";
import LandingSectionHero from "~/components/marketing/landing/LandingSectionHero";
// import LandingSectionLikeYou from "~/components/marketing/landing/LandingSectionLikeYou";
import LandingSectionMainFeatures from "~/components/marketing/landing/LandingSectionMainFeatures";
import LandingSectionDesignShop from "~/components/marketing/landing/LandingSectionDesignShop";
import LandingSectionProviders from "~/components/marketing/landing/LandingSectionProviders";
import LandingSectionSmallCallToAction from "~/components/marketing/landing/LandingSectionSmallCallToAction";
import LandingSectionChat from "~/components/marketing/landing/LandingSectionChat";
import LandingSectionTelescope from "~/components/marketing/landing/LandingSectionTelescope";
import LandingSectionSearch from "~/components/marketing/landing/LandingSectionSearch";
import LandingSectionLargeCallToAction from "~/components/marketing/landing/LandingSectionLargeCallToAction";
import LandingSectionOtherFeatures from "~/components/marketing/landing/LandingSectionOtherFeatures";
import Header from "~/components/marketing/Header";
import Footer from "~/components/marketing/Footer";

export default function LandingPage() {
  // Initialize Crisp Chat
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "faafb953-7f75-488f-94fa-4d53c773e52e";

    var d = document;
    var s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  }, []);

  return (
    <Page
      title="Creá tu tienda en internet | Shop Story"
      description="Vendé sin condiciones con tu propia tienda en internet. Creá fácil tu negocio online, realizá envíos y aceptá pagos digitales para tu negocio."
    >
      <LightMode>
        <VStack
          w="100%"
          maxW="100vw"
          overflowX="hidden"
          spacing="0"
          align="flex-start"
        >
          <Header />
          <LandingSectionHero />
          {/* DISABLED <LandingSectionLikeYou /> */}
          <LandingSectionDesignShop />
          <LandingSectionMainFeatures />
          <LandingSectionProviders />
          <LandingSectionSmallCallToAction />
          <LandingSectionChat />
          <LandingSectionTelescope />
          <LandingSectionSearch />
          <LandingSectionOtherFeatures />
          <LandingSectionLargeCallToAction />
          <Footer />
        </VStack>
      </LightMode>
      <style global jsx>{`
        html,
        body {
          background-color: #ffffff;
        }
      `}</style>
    </Page>
  );
}
