import { useState, useEffect } from "react";
import { Box, VStack, HStack, Center, Wrap, WrapItem } from "@chakra-ui/react";
import { Display03, Label01, Label02, Paragraph03 } from "~/components/Texts";
import {
  FiSearch,
  FiPackage,
  FiGrid,
  FiShoppingBag,
  FiUsers,
} from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import { useInView } from "react-intersection-observer";
import Img from "react-optimized-image";
import SearchResultsImage from "~/public/marketing/landing_search_results.png";
import CharacterImage from "~/public/marketing/landing_search_character.png";

export default function LandingSectionSearch({}) {
  return (
    <Box
      w="100%"
      h={{ base: "750px", md: "900px", lg: "1100px", xl: "1100px" }}
      maxW="1200px"
      pt={{ base: "4", xl: "0" }}
      pb={{ base: "4", md: "10" }}
      px="4"
      alignSelf="center"
      color="light.brand.strong"
      backgroundColor={{
        base: "light.background.strong",
        md: "light.background.superLite",
      }}
    >
      <AnimatedEntryOnViewport>
        <Box position="relative" w="100%" h="100%">
          <Box
            display={{ base: "none", md: "block" }}
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
          >
            <Background />
          </Box>

          <VStack
            position="absolute"
            top="0"
            left="0"
            w="100%"
            spacing="0"
            px={{ base: "0", md: "20" }}
            py={{ base: "4", md: "10" }}
          >
            <SearchBar />
            <Img
              src={SearchResultsImage}
              webp
              sizes={[300, 600, 900, 1000, 1100]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
            />
            <HStack pt={{ base: "4", md: "0" }} spacing="4">
              <Box display={{ base: "none", md: "block" }} maxW="225px">
                <Img
                  src={CharacterImage}
                  webp
                  sizes={[75, 125, 225]}
                  densities={[1, 2]} // Retina support
                  draggable="false"
                  loading="lazy"
                />
              </Box>
              <VStack color="light.brand.lite" spacing="4">
                <Paragraph03>
                  <b>Encontrá eso que estas buscando.</b> El panel de control de
                  tu negocio te permite buscar entre productos, colecciones,
                  ventas, visitantes y más.
                </Paragraph03>
                <Paragraph03>
                  Tu tienda online también incluye un buscador de productos para
                  tus visitantes sin que tengas que configurar nada.
                </Paragraph03>
              </VStack>
            </HStack>
          </VStack>
        </Box>
      </AnimatedEntryOnViewport>
    </Box>
  );
}

const SearchBar = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <VStack w="100%" align="flex-start" spacing="4" pb="6">
      <Label01>Buscá en todo tu negocio</Label01>
      <HStack
        ref={ref}
        w="100%"
        p="4"
        spacing="4"
        borderRadius="brand"
        backgroundColor="light.background.superLite"
        boxShadow="lg"
        borderWidth="2px"
        borderColor="light.brand.action"
      >
        <FiSearch color="ligth.brand.strong" size="32px" />
        {inView && <Typewriter />}
      </HStack>
      <Wrap w="100%" spacing="4">
        <WrapItem>
          <SearchType icon={<FiPackage size="24px" />} title="Productos" />
        </WrapItem>
        <WrapItem>
          <SearchType icon={<FiGrid size="24px" />} title="Colecciones" />
        </WrapItem>
        <WrapItem>
          <SearchType icon={<FiShoppingBag size="24px" />} title="Ventas" />
        </WrapItem>
        <WrapItem>
          <SearchType icon={<FiUsers size="24px" />} title="Visitantes" />
        </WrapItem>
      </Wrap>
    </VStack>
  );
};

const SearchType = ({ icon, title }) => {
  return (
    <HStack w="100%" h="100%">
      <Center
        p="2"
        borderRadius="full"
        backgroundColor="light.background.action"
      >
        {icon}
      </Center>
      <Label02>{title}</Label02>
    </HStack>
  );
};

const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#fbfbfb"
      d="M1244.17 92.005L123.008.64a8 8 0 00-8.612 7.197L.821 1172.88a8 8 0 007.632 8.77l1283.847 52.99c4.68.19 8.52-3.66 8.32-8.34L1251.51 99.631a8 8 0 00-7.34-7.625z"
    />
  </svg>
);

// Original source (modified): https://levelup.gitconnected.com/typing-effect-in-react-56697def0473
const Typewriter = () => {
  const [letterIndex, setLetterIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const typingWord = "Lentes de sol";

  // Letters
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterIndex((prev) => prev + 1);
    }, Math.max(letterIndex === typingWord.length ? 1000 : 150, parseInt(Math.random() * 350)));

    return () => clearTimeout(timeout);
  }, [letterIndex]);

  // Blinker
  useEffect(() => {
    const blinkerTimout = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(blinkerTimout);
  }, [blink]);

  return (
    <Display03 color="action.primary">
      {`${typingWord.substring(0, letterIndex)}${blink ? "|" : " "}`}
    </Display03>
  );
};
