import { Box, VStack, Flex } from "@chakra-ui/react";
import { Display03, Paragraph02 } from "~/components/Texts";
import FeaturePoint from "~/components/marketing/FeaturePoint";
import { FiRepeat, FiUsers } from "react-icons/fi";
import { IoQrCode } from "react-icons/io5";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Img from "react-optimized-image";
import CharacterImage from "~/public/marketing/landing_products_character_tablet.png";
import ProductsBlocksImage from "~/public/marketing/landing_products_blocks.png";
import ProductsTableImage from "~/public/marketing/landing_products_table.png";

export default function LandingSectionFeaturePublishProducts({}) {
  return (
    <AnimatedEntryOnViewport>
      <Flex
        direction={{ base: "column-reverse", lg: "row" }}
        w="100%"
        color="light.brand.strong"
      >
        <VStack flex="1" align="flex-start" spacing="8">
          <Box
            px="2"
            borderRadius="brand"
            borderWidth="2px"
            borderColor="action.primary"
            color="action.primary"
          >
            <Display03>Publicá tus productos</Display03>
          </Box>
          <Paragraph02 maxW="600px">
            <b>Controlá tus productos a la venta.</b> Lleva el control de
            unidades en venta, productos, colecciones de productos, precios,
            descuentos y más desde el panel de control de tu tienda.
          </Paragraph02>
          <VStack spacing="4">
            <FeaturePoint icon={<FiRepeat />} title="Inventario sincronizado" />
            <FeaturePoint icon={<FiUsers />} title="100% colaborativo" />
            <FeaturePoint icon={<IoQrCode />} title="Accesible por QR" />
            <Box
              display={{ base: "none", lg: "block" }}
              width={{ lg: "125px", xl: "200px" }}
            >
              <Img
                src={CharacterImage}
                webp
                sizes={[125, 200]}
                densities={[1, 2]} // Retina support
                draggable="false"
                loading="lazy"
              />
            </Box>
          </VStack>
        </VStack>
        <Box boxSize="8" />
        <VStack flex="1" spacing="10">
          <AnimatedEntryOnViewport animation="popIn">
            <Img
              src={ProductsBlocksImage}
              webp
              sizes={[150, 300, 600]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Una persona modifica un producto publicado en una tienda"
            />
          </AnimatedEntryOnViewport>
          <AnimatedEntryOnViewport animation="popIn">
            <Img
              src={ProductsTableImage}
              webp
              sizes={[150, 300, 600]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Listado de productos publicados en una tienda"
            />
          </AnimatedEntryOnViewport>
        </VStack>
      </Flex>
    </AnimatedEntryOnViewport>
  );
}
