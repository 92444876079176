import { Box, VStack, Stack, useTheme } from "@chakra-ui/react";
import { Display02, Paragraph02 } from "~/components/Texts";
import FeaturePoint from "~/components/marketing/FeaturePoint";
import { FiMapPin, FiShoppingCart, FiFastForward } from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Img from "react-optimized-image";
import TelescopeImage from "~/public/marketing/landing_telescope_mockup.png";

export default function LandingSectionTelescope() {
  const chakraTheme = useTheme();

  return (
    <AnimatedEntryOnViewport animation="slideFromRight" delay={0.5}>
      <Box
        w="100%"
        h={{ base: "720px", md: "700px", xl: "1000px" }}
        py={{ base: "4", xl: "10" }}
        alignSelf="center"
        backgroundColor={{
          base: "light.brand.strong",
          xl: "light.background.superLite",
        }}
      >
        <Box position="relative" w="100%" h="100%">
          <Box position="absolute" top="0" left="0" w="100%" h="100%" px="10">
            <Background />
          </Box>

          <Stack
            direction={{ base: "column-reverse", md: "row" }}
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            spacing={{ base: "4", xl: "12" }}
            align={{ md: "center" }}
          >
            <VStack
              flex={{ base: "2", md: "1" }}
              align={{ sm: "center", xl: "flex-end" }}
              pl="4"
            >
              <AnimatedEntryOnViewport width="auto" animation="popIn">
                <VStack
                  color="light.background.superLite"
                  align="flex-start"
                  spacing="4"
                >
                  <Display02 maxW="600px">
                    Un{" "}
                    <mark
                      style={{
                        background:
                          "linear-gradient(180deg,rgba(255,255,255,0) 50%, #3E4462 50%)",
                        color: chakraTheme.colors.action.secondary,
                      }}
                    >
                      Telescopio
                    </mark>{" "}
                    para atender a tus clientes
                  </Display02>
                  <Paragraph02 maxW="600px">
                    <b>
                      Mirá en vivo las interacciones de tus visitantes en tu
                      tienda.
                    </b>{" "}
                    Cuidamos la privacidad de tus clientes, por eso su identidad
                    permancerá anónima.
                  </Paragraph02>
                  <VStack pt="6" spacing="4">
                    <FeaturePoint
                      icon={<FiMapPin />}
                      title="Perfil del visitante"
                      isDark
                    />
                    <FeaturePoint
                      icon={<FiShoppingCart />}
                      title="Carrito en vivo"
                      isDark
                    />
                    <FeaturePoint
                      icon={<FiFastForward />}
                      title="Historial de acciones"
                      isDark
                    />
                  </VStack>
                </VStack>
              </AnimatedEntryOnViewport>
            </VStack>
            <Box flex="1" h="100%" display={{ sm: "none", md: "block" }}>
              <Img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "0% 50%",
                }}
                src={TelescopeImage}
                webp
                sizes={[300, 600, 800]}
                densities={[1, 2]} // Retina support
                draggable="false"
                loading="lazy"
                alt="Datos del visitante en vivo. Ubicación, idioma, plataforma, productos en carrito, productos vistos, productos viendo en este momento."
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </AnimatedEntryOnViewport>
  );
}

const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1332 807"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#18214d"
      d="M1217.55.834L7.426 130.999c-4.573.492-7.79 4.729-7.036 9.266l92.364 555.731a8 8 0 007.217 6.659L1323.08 806.16a8 8 0 008.6-9.029L1226.34 7.73c-.58-4.301-4.48-7.36-8.79-6.896z"
    />
  </svg>
);
