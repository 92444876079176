import { Box, VStack, Button, Stack, Text, useTheme } from "@chakra-ui/react";
import { Display02 } from "~/components/Texts";
import { FiArrowRight } from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Link from "next/link";

export default function LandingSectionSmallCallToAction() {
  const chakraTheme = useTheme();

  return (
    <AnimatedEntryOnViewport animation="slideFromLeft" delay={0.5}>
      <Box
        w="100%"
        h={{ base: "320px", md: "350px", xl: "400px" }}
        w="100%"
        px="4"
        py={{ base: "6", xl: "10" }}
        alignSelf="center"
        backgroundColor={{
          base: "light.background.lite",
          lg: "light.background.superLite",
        }}
        color="light.brand.strong"
      >
        <Box position="relative" w="100%" h="100%" spacing="8">
          <Box position="absolute" top="0" left="0" w="100%" h="100%" px="10">
            <Background />
          </Box>

          <Stack
            w="100%"
            direction={{ base: "column", md: "row" }}
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            align="center"
            spacing="1"
          >
            <VStack flex="1" align="flex-end">
              <Box maxW="600px">
                <Display02 textAlign={{ base: "center", md: "right" }}>
                  Empezá{" "}
                  <span style={{ color: chakraTheme.colors.action.primary }}>
                    tu historia
                  </span>{" "}
                  ahora por{" "}
                  <span style={{ color: chakraTheme.colors.action.secondary }}>
                    $0.00
                  </span>
                </Display02>
              </Box>
            </VStack>
            <Box boxSize={{ base: "0", md: "8" }} />
            <VStack
              flex="1"
              align={{ base: "center", md: "flex-start" }}
              spacing="4"
            >
              <Link href="/auth/signup">
                <Button size="lg" rightIcon={<FiArrowRight />}>
                  Creá tu tienda ahora
                </Button>
              </Link>
              <Link href="/pricing">
                <a>
                  <Text wordBreak="break-word" textAlign="center">
                    <b>Comenzá gratis, sólo pagas después de vender</b>
                  </Text>
                </a>
              </Link>
            </VStack>
          </Stack>
        </Box>
      </Box>
    </AnimatedEntryOnViewport>
  );
}

const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1191 249"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#fbfbfb"
      d="M1298.7 59.323L9.166.419C4.319.197.393 4.306.834 9.137L68.575 751.47a8 8 0 008.244 7.268l1167.931-40.47a8 8 0 007.69-7.328l53.87-642.957c.38-4.521-3.08-8.452-7.61-8.659z"
    />
  </svg>
);
