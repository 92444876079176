import { Box, VStack, useTheme } from "@chakra-ui/react";
import { Display02 } from "~/components/Texts";
import LandingSectionFeaturePublishProducts from "~/components/marketing/landing/LandingSectionFeaturePublishProducts";
import LandingSectionFeatureSalesProcess from "~/components/marketing/landing/LandingSectionFeatureSalesProcess";
import LandingSectionFeatureAnalytics from "~/components/marketing/landing/LandingSectionFeatureAnalytics";

export default function LandingSectionMainFeatures() {
  const chakraTheme = useTheme();

  return (
    <Box
      w="100%"
      maxW="1500px"
      alignSelf="center"
      px={{ base: "0", md: "10", lg: "20" }}
      py={{ base: "4", md: "10" }}
    >
      <VStack w="100%" spacing="0">
        <Box w="100%">
          <Header />
        </Box>
        <VStack
          w="100%"
          backgroundColor="light.background.lite"
          px={{ base: "4", md: "8" }}
          spacing={{ base: "10", md: "14", lg: "20" }}
        >
          <Display02
            color="light.brand.strong"
            pb={{ base: "0", md: "6" }}
            textAlign="center"
          >
            <mark
              style={{
                background:
                  "linear-gradient(180deg,rgba(255,255,255,0) 50%, #F4F4F4 50%)",
                color: chakraTheme.colors.action.primary,
              }}
            >
              Simplificá
            </mark>{" "}
            el control de tu negocio
          </Display02>
          <LandingSectionFeaturePublishProducts />
          <LandingSectionFeatureSalesProcess />
          <LandingSectionFeatureAnalytics />
        </VStack>
        <Box w="100%">
          <Footer />
        </Box>
      </VStack>
    </Box>
  );
}

const Header = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1072 67"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#fbfbfb"
      fillRule="evenodd"
      d="M1072 67c-4.5-44.5-119-65.051-179-57.534-59 7.806-119 32.67-178 37.296-60 4.626-119-10.697-179-23.129S417 1.949 357 .214C298-1.232 238 4.84 179 12.646 119 20.452 11 30 0 67h1072z"
    />
  </svg>
);

const Footer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1072 67"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#fbfbfb"
      fillRule="evenodd"
      d="M0 0c4.5 44.5 119 65.051 179 57.534 59-7.806 119-32.67 178-37.296 60-4.626 119 10.697 179 23.129s119 21.684 179 23.418c59 1.446 119-4.626 178-12.432C953 46.548 1061 37 1072 0H0z"
    />
  </svg>
);
