import {
  Box,
  VStack,
  Center,
  HStack,
  DarkMode,
  Text,
  Stack,
  useTheme,
} from "@chakra-ui/react";
import { Display02, Display03, Label01, Paragraph04 } from "~/components/Texts";
import { FiCreditCard, FiTruck, FiLogOut } from "react-icons/fi";
import { HiSwitchHorizontal } from "react-icons/hi";
import Logo from "~/components/logo";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Img from "react-optimized-image";
import Svg from "react-optimized-image";
import CharacterMariachiImage from "~/public/marketing/landing_providers_character_mariachi.png";
import CharacterDeliveryImage from "~/public/marketing/landing_providers_character_delivery.png";
import ArgentinaFlagImage from "~/public/flags/flag_ARG.svg";
import MercagoPagoLogoImage from "~/public/marketing/landing_providers_logo_mercadopago.svg";
import TodoPagoLogoImage from "~/public/marketing/landing_providers_logo_todopago.svg";
import OcaLogoImage from "~/public/marketing/landing_providers_logo_oca.svg";

export default function LandingSectionSmallCallToAction() {
  const chakraTheme = useTheme();

  return (
    <AnimatedEntryOnViewport>
      <Box
        w="100%"
        h={{ base: "1100px", sm: "1000px", md: "900px", xl: "950px" }}
        w="100%"
        py={{ sm: "6", xl: "10" }}
        alignSelf="center"
        backgroundColor={{
          base: "light.brand.strong",
          xl: "light.background.superLite",
        }}
        color="light.background.superLite"
      >
        <Box position="relative" w="100%" h="100%" spacing="8">
          <Box
            display={{ base: "none", md: "block" }}
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            px="10"
          >
            <Background />
          </Box>

          <Box
            position="absolute"
            top={{ base: "-10%", xl: "-5%" }}
            left={{ base: "2.5%", xl: "5%" }}
            width={{ base: "150px", xl: "200px", "2xl": "300px" }}
            display={{ base: "none", lg: "block" }}
          >
            <Img
              src={CharacterMariachiImage}
              webp
              sizes={[75, 150, 300]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Mariachi festejando porque compró por internet"
            />
          </Box>

          <Box
            position="absolute"
            bottom={{ base: "-10%", xl: "-5%" }}
            right={{ base: "2.5%", xl: "5%" }}
            width={{ base: "125px", xl: "200px", "2xl": "250px" }}
            display={{ base: "none", lg: "block" }}
          >
            <Img
              src={CharacterDeliveryImage}
              webp
              sizes={[75, 125, 250]}
              densities={[1, 2]} // Retina support
              draggable="false"
              loading="lazy"
              alt="Correo entregando una compra por internet"
            />
          </Box>

          <VStack
            w="100%"
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            justify="center"
            spacing="12"
          >
            <Display02 textAlign="center">
              ¡Sos{" "}
              <mark
                style={{
                  background:
                    "linear-gradient(180deg,rgba(255,255,255,0) 50%, #3E4462 50%)",
                  color: chakraTheme.colors.action.secondary,
                }}
              >
                libre
              </mark>{" "}
              de vender como quieras!
            </Display02>
            <Stack
              direction={{ base: "column", md: "row" }}
              w={{ base: "100%", md: "90%", lg: "80%", xl: "60%" }}
              spacing={{ base: "10", md: "4" }}
              px="4"
              align="flex-start"
            >
              <VStack flex="1" spacing={{ base: "2", md: "6" }}>
                <HStack w="100%" pb="4">
                  <FiCreditCard size="36px" />
                  <Display03>Pagos</Display03>
                </HStack>
                <ProviderBox isFeatured>
                  <VStack w="100%">
                    <HStack w="100%">
                      <DarkMode>
                        <Logo type="icon" height="38px" width="48px" />
                      </DarkMode>
                      <Label01>Libre</Label01>
                    </HStack>
                    <Text
                      textStyle={{ base: "paragraph04", md: "paragraph02" }}
                    >
                      Cobrás directo a tus clientes, con el método que
                      prefieras. Sin comisiones por cobrar ni condiciones
                      impuestas.
                    </Text>
                  </VStack>
                </ProviderBox>
                <ProviderBox>
                  <VStack w="100%">
                    <HStack w="100%">
                      <Svg
                        src={MercagoPagoLogoImage}
                        alt="Logo de MercadoPago"
                      />
                      <Box flex="1" />
                      <Svg
                        style={{ height: "24px" }}
                        src={ArgentinaFlagImage}
                        alt="Argentina"
                      />
                    </HStack>
                    <Text
                      textStyle={{ base: "paragraph04", md: "paragraph02" }}
                    >
                      Cobrás online a tus clientes utilizando MercadoPago. Los
                      pagos se registran automáticamente en tu tienda.
                    </Text>
                  </VStack>
                </ProviderBox>
                <ProviderBox>
                  <VStack w="100%">
                    <HStack w="100%" justify="space-between">
                      <Box>
                        <Svg src={TodoPagoLogoImage} alt="Logo de TodoPago" />
                      </Box>
                      <Box
                        px="2"
                        backgroundColor="action.secondary"
                        borderRadius="brand"
                      >
                        <Paragraph04>
                          <b>MUY PRONTO</b>
                        </Paragraph04>
                      </Box>
                    </HStack>
                    <Text
                      textStyle={{ base: "paragraph04", md: "paragraph02" }}
                    >
                      Cobrás desde tu tienda online a través de TodoPago. Los
                      pagos se registran automáticamente en tu tienda.
                    </Text>
                  </VStack>
                </ProviderBox>
              </VStack>
              <Box
                px={{ base: "0", lg: "8" }}
                h="100%"
                display={{ base: "none", md: "block" }}
              >
                <Center h="100%">
                  <HiSwitchHorizontal size="36px" />
                </Center>
              </Box>
              <VStack flex="1" spacing={{ base: "2", md: "6" }}>
                <HStack w="100%" pb="4">
                  <FiTruck size="36px" />
                  <Display03>Envíos</Display03>
                </HStack>
                <ProviderBox isFeatured>
                  <VStack w="100%">
                    <HStack w="100%">
                      <DarkMode>
                        <Logo type="icon" height="38px" width="48px" />
                      </DarkMode>
                      <Label01>Libre</Label01>
                    </HStack>
                    <Text
                      textStyle={{ base: "paragraph04", md: "paragraph02" }}
                    >
                      Envías las compras a tus clientes como prefieras. Podés
                      coordinar el envío o elegir el proveedor que quieras.
                    </Text>
                  </VStack>
                </ProviderBox>
                <ProviderBox>
                  <VStack w="100%">
                    <HStack w="100%">
                      <FiLogOut size="24px" />
                      <Label01>Retiro en tienda</Label01>
                    </HStack>
                    <Text
                      textStyle={{ base: "paragraph04", md: "paragraph02" }}
                    >
                      Podés permitir que los clientes retiren sus compras
                      directo en tu local, específicando la dirección al momento
                      de la compra.
                    </Text>
                  </VStack>
                </ProviderBox>
                <ProviderBox>
                  <VStack w="100%">
                    <HStack w="100%" justify="space-between">
                      <Box>
                        <Svg src={OcaLogoImage} alt="Logo de OCA" />
                      </Box>
                      <Box
                        px="2"
                        backgroundColor="action.secondary"
                        borderRadius="brand"
                      >
                        <Paragraph04>
                          <b>MUY PRONTO</b>
                        </Paragraph04>
                      </Box>
                    </HStack>
                    <Text
                      textStyle={{ base: "paragraph04", md: "paragraph02" }}
                    >
                      Envías las compras de tus clientes a través de OCA. Los
                      envíos se registran automáticamente en tu tienda.
                    </Text>
                  </VStack>
                </ProviderBox>
              </VStack>
            </Stack>
          </VStack>
        </Box>
      </Box>
    </AnimatedEntryOnViewport>
  );
}

const Background = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1301 940"
    height="100%"
    width="100%"
    fill="none"
  >
    <path
      fill="#18214d"
      d="M1292.93 73.481L9.099.517C4.277.243.313 4.272.667 9.089l67.71 923.169a8 8 0 008.323 7.408l1162.241-50.108a7.995 7.995 0 007.64-7.455l53.88-800.097c.3-4.441-3.09-8.272-7.53-8.525z"
    ></path>
  </svg>
);

const ProviderBox = ({ header, children, isFeatured }) => {
  return (
    <AnimatedEntryOnViewport animation="popIn" duration={0.5} delay={0.1}>
      <Box
        w="100%"
        px="4"
        py="2"
        borderRadius="brand"
        backgroundColor="light.brand.lite"
        borderWidth="2px"
        borderColor={isFeatured ? "action.secondary" : "#6E7389"}
        spacing="4"
        color="light.background.superLite"
      >
        {children}
      </Box>
    </AnimatedEntryOnViewport>
  );
};
