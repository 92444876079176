import {
  Box,
  VStack,
  Center,
  Wrap,
  WrapItem,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { Display02 } from "~/components/Texts";
import { FiEdit3, FiMoon, FiPackage, FiShield, FiTag } from "react-icons/fi";
import { HiOutlineLightningBolt } from "react-icons/hi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";

export default function LandingSectionOtherFeatures() {
  const chakraTheme = useTheme();

  return (
    <VStack
      w="100%"
      pt={{ base: "16", md: "32" }}
      pb={{ base: "8", md: "10" }}
      px="4"
      spacing={{ base: "10", md: "20" }}
      color="light.brand.strong"
    >
      <Box>
        <AnimatedEntryOnViewport>
          <Display02 maxW="800px" textAlign="center">
            Aprovechá ahora grandes{" "}
            <mark
              style={{
                background:
                  "linear-gradient(180deg,rgba(255,255,255,0) 50%, #F4F4F4 50%)",
                color: chakraTheme.colors.action.primary,
              }}
            >
              ventajas
            </mark>{" "}
            para tu negocio.
          </Display02>
        </AnimatedEntryOnViewport>
      </Box>
      <Wrap
        w="100%"
        maxW="1200px"
        justify="center"
        spacing={{ base: "8", md: "10" }}
      >
        <WrapItem w="320px">
          <AnimatedEntryOnViewport animation="popIn" duration={0.5}>
            <Feature
              icon={<HiOutlineLightningBolt size="24px" />}
              title="Velocidad relámpago"
              summary="Tu tienda carga rápido en todo el mundo, en todo momento y en todos los dispositivos."
            />
          </AnimatedEntryOnViewport>
        </WrapItem>
        <WrapItem w="320px">
          <AnimatedEntryOnViewport animation="popIn" duration={0.5}>
            <Feature
              icon={<FiShield size="24px" />}
              title="Permisos por usuario"
              summary="Asigná distintos permisos para cada uno de los miembros de tu equipo."
            />
          </AnimatedEntryOnViewport>
        </WrapItem>
        <WrapItem w="320px">
          <AnimatedEntryOnViewport animation="popIn" duration={0.5}>
            <Feature
              icon={<FiPackage size="24px" />}
              title="Inventario automático"
              summary="Tu tienda lleva el registro de cuanto stock tenés de cada producto y pausa las publicaciones de productos agotados."
            />
          </AnimatedEntryOnViewport>
        </WrapItem>
        <WrapItem w="320px">
          <AnimatedEntryOnViewport animation="popIn" duration={0.5}>
            <Feature
              icon={<FiEdit3 size="24px" />}
              title="Ventas por otros medios gratis"
              summary="Unificá el registro de tus ventas por otros medios generando ventas en forma manual sin pagar comisiones."
            />
          </AnimatedEntryOnViewport>
        </WrapItem>
        <WrapItem w="320px">
          <AnimatedEntryOnViewport animation="popIn" duration={0.5}>
            <Feature
              icon={<FiTag size="24px" />}
              title="Descuentos y promociones"
              summary="Generá cupones de descuento y llevá el control de los precios de cada uno de tus productos."
            />
          </AnimatedEntryOnViewport>
        </WrapItem>
        <WrapItem w="320px">
          <AnimatedEntryOnViewport animation="popIn" duration={0.5}>
            <Feature
              icon={<FiMoon size="24px" />}
              title="Modo oscuro"
              summary="Unite al lado oscuro. El panel de control de tu tienda viene con un modo nocturno para cuidar tus ojitos."
            />
          </AnimatedEntryOnViewport>
        </WrapItem>
      </Wrap>
    </VStack>
  );
}

const Feature = ({ icon, title, summary }) => {
  return (
    <VStack w="100%" h="100%">
      <Center
        p="4"
        borderRadius="full"
        backgroundColor="light.background.action"
      >
        {icon}
      </Center>
      <Text textStyle={{ base: "label02", md: "label01" }} textAlign="center">
        {title}
      </Text>
      <Text
        textStyle={{ base: "paragraph03", md: "paragraph02" }}
        textAlign="center"
      >
        {summary}
      </Text>
    </VStack>
  );
};
